import React from 'react'
import {StaticQuery, graphql} from "gatsby";

import Layout from '../components/layout'
import {Grid, Typography, Container, makeStyles} from '@material-ui/core';

import HexaBlackLogo from '../gfx/logo_BS_201202.svg';

import BinaryBackground from '../gfx/back_binary_wide_201117.svg';

import Carousel from "../components/carousel";
import Contact from "../components/contact";
import MidSiteLogo from "../../assets/logo_midsite.svg";
import MidpageLogo from "../components/midpageLogo";
import SectionContainer from "../components/sectionContainer";
import ProductBlock from "../components/productBlock";
import ExpertiseIconBrain from "../../assets/expertise/expertise_icon_brain_210323.svg";
import ExpertiseIconRocket from "../../assets/expertise/expertise_icon_rocket_210323.svg";
import ExpertiseIconTool from "../../assets/expertise/expertise_icon_tool_210323.svg";
import AboutSection from "../components/about";

const useStyles = makeStyles({
  expertiseTopGradient: {
    background: "linear-gradient(180deg, rgba(14,17,24,1) 0%, rgba(14,17,24,0) 100%)",
    width: "100%",
    height: "20vh",

    // Overcome parent padding
    position: "absolute",
    top: 0,
    left: 0
  },
  expertiseBottomGradient: {
    background: "linear-gradient(180deg, rgba(0, 59, 109, 0) 0%, rgba(0, 59, 109, 1) 100%)",
    width: "100%",
    height: "20vh",

    // Overcome parent padding
    position: "absolute",
    bottom: 0,
    left: 0
  },
});

export default () => {
  const classes = useStyles();
  return (
    <Layout>

        {/** Slider **/}
        <Carousel startPage={1} />

        {/** Logo-intermediate **/}
        <MidpageLogo/>

        {/** About **/}
        <AboutSection>
          We’re confident in our ability to help you unlock your AI’s true potential and that’s why we even offer a
          guarantee – faster or free.
        </AboutSection>

        {/** Product Descriptions - binary background */}
      <SectionContainer backgroundImage={BinaryBackground}>
        <div className={classes.expertiseTopGradient}/>
        <Grid container
              alignItems="center"
              justify="center"
              style={{
                padding: "15vh 5vw",
                position: "relative", // Enable zIndex
                zIndex: 1
              }}
        >
          <ProductBlock title="The best for your AI">
            By having us review your AI, you’ll have a global team of experts looking at the most fundamental aspect of
            what makes it work – the software.
            <br/>
            <br/>
            While most companies won’t touch the underlying code or libraries involved, we’ve made it our specialty,
            and experience has taught us that there are huge improvements to be had here.
            <br/>
            <br/>
            Naturally, our solutions are always tailor-made for your specific AI – ensuring that your AI gets what’s
            truly best for it.
          </ProductBlock>
          <ProductBlock title="A unique value proposition - Faster or Free">
            We’ll make your AI faster. Performance, low-latency and efficient computation stand at the core of
            everything we do and we can do the same for you.
            <br/>
            <br/>
            Whether to observe, learn or simply see – we’ll tell you exactly what we did and why so you can build on
            our work, learn from it and have an easier time applying it.
            <br/>
            <br/>
            Transparency builds trust. That’s why we offer example reports on released work and open source projects so
            you can see for yourself what we do.
          </ProductBlock>
          <ProductBlock title="We make it easy for you">
            We’ve set up our system to provide the greatest ease-of-use and fastest response times for you, simply take
            a look below at our [customer journey] to find out more.
            <br/>
            <br/>
            When needed, we can provide a test environment. Either on our hardware or in the cloud should you require
            it. Help with onboarding is a given.
            <br/>
            <br/>
            For those who want everything, there’s always the option of making use of our own unique libraries and
            ancillary services should you be interested in working with us further.
          </ProductBlock>
        </Grid>
        <div className={classes.expertiseBottomGradient}/>
      </SectionContainer>
    </Layout>
  )
};
